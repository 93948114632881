import { AccountInfo, AuthenticationResult, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
type HTTPMethod = 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE' | 'HEAD';


export class Core {
    private coreInstance!: Core;
    private instance!: IPublicClientApplication;
    private graphToken!: AuthenticationResult;
    private integrationHubToken!: AuthenticationResult;
    private account!: AccountInfo;
    private timeNow = new Date();
    constructor(graphToken?: string, integrationHubToken?: string) {
        if (graphToken && integrationHubToken) {

            this.graphToken.accessToken = graphToken;
            this.integrationHubToken.accessToken = integrationHubToken;
        }
    }

    public initialiseCore = async (pcaInstance: PublicClientApplication, account: AccountInfo) => {
        if (this.coreInstance) {
            //console.log("Already initialised..");
            const epochExpiry = account.idTokenClaims?.exp || null;
            if (epochExpiry && this.minutesBetweenDates(this.epochSecondsToDate(epochExpiry)) >= 50) {
                this.graphToken = await this.getGraphToken();
                this.integrationHubToken = await this.getIntegrationHubToken();
                return this.coreInstance;
            } else {
                return this.coreInstance;
            }
        } else {
            this.instance = pcaInstance;
            this.account = account;
            // Get the graph & Integration Hub token for the current session.
            this.graphToken = await this.getGraphToken();
            this.integrationHubToken = await this.getIntegrationHubToken();

            this.coreInstance = this;
            return this.coreInstance;
        }
    }

    //#region Token retrieval methods.
    /**
     * Retrieves a Integration Hub token.
     * @return {Promise<AuthenticationResult>} A promise that resolves to the integration hub token.
     */
    private getIntegrationHubToken = async (): Promise<AuthenticationResult> => {
        const tokenRequest = await this.instance.acquireTokenSilent({
            account: this.account,
            scopes: ["api://fa88af55-9d18-423d-9b5e-41b82d8a46a1/Identity.Frontend"],
            forceRefresh: true
        });

        return tokenRequest;
    }

    /**
     * Retrieves a MS Graph token.
     * @return {Promise<AuthenticationResult>} A promise that resolves to the integration hub token.
     */
    private getGraphToken = async (): Promise<AuthenticationResult> => {
        const tokenRequest = await this.instance.acquireTokenSilent({
            account: this.account,
            scopes: ["User.ReadWrite", "CustomSecAttributeAssignment.Read.All", "CustomSecAttributeAssignment.Read.All", "User.Read.All"],
            forceRefresh: true
        });
        return tokenRequest;
    }
    //#endregion

    //#region 
    /**
     * Invokes an HTTP request to the Integration Hub. 
     * @param {HTTPMethod} method
     * @param {string} path
     * @param {object} [payload]
     * @param {object} [additionalHeaders]
     */
    public invokeIntegrationHubRequest = async (method: HTTPMethod, path: string, payload?: object, additionalHeaders?: object): Promise<{ body: object | string, statusCode: number }> => {
        if (!this.integrationHubToken) {
            await this.getIntegrationHubToken();
        }
        if (this.integrationHubToken.expiresOn) {
            if ((this.integrationHubToken.expiresOn.getTime() - new Date().getTime()) - (1000 * 60) < 0) {
                await this.getIntegrationHubToken();
            }
        }
        try {
            const response = await fetch(`https://rtbh2wzg97.execute-api.eu-west-1.amazonaws.com/stg${path}`, {
                method: method,
                headers: {
                    SubscriptionKey: "5a0a2643a0c34a75aa0e79376fbb8b77",
                    Authorization: `Bearer ${this.integrationHubToken.accessToken}`,
                    "Content-Type": "application/json",
                    FrontendMarker: "integration-hub",
                    // APIVersion: env?.NODE_ENV !== "production" ? "beta" : "v1.0",
                    ...additionalHeaders
                },
                body: payload ? JSON.stringify(payload) : undefined,
                referrerPolicy: "no-referrer"
            });

            let responseBody: string | object;

            responseBody = await response.text();
            try {
                responseBody = JSON.parse(responseBody);
            } catch (error) {
                console.log(responseBody);
            }

            return {
                statusCode: response.status,
                body: responseBody
            }
        } catch (error) {
            return {
                statusCode: 400,
                body: `Integration Hub request: Something went wrong. Error ${error}, ${path}`
            }
        }
    }

    private minutesBetweenDates = (createdDateTime: Date): number => {
        const currentDateTime = new Date();

        const timeDifference = currentDateTime.getTime() - createdDateTime.getTime();
        const minutesDifference = Math.round(timeDifference / 60000);

        return minutesDifference;
    }

    private epochSecondsToDate(epochSeconds: number): Date {
        const milliseconds = epochSeconds * 1000;
        return new Date(milliseconds);
    }

    /**
     * Invokes an HTTP request to the Graph.
     * @param {HTTPMethod} method
     * @param {string} path
     * @param {object} [payload]
     * @param {object} [additionalHeaders]
     * @memberof Core
     */
    public invokeGraphRequest = async (method: HTTPMethod, path: string, payload?: object, additionalHeaders?: object): Promise<{ body: object | string, statusCode: number }> => {
        if (!this.graphToken) {
            await this.getGraphToken();
        }
        if (this.graphToken.expiresOn) {
            if ((this.graphToken.expiresOn.getTime() - new Date().getTime()) - (1000 * 60) < 0) {
                await this.getGraphToken();
            }
        }
        try {
            const response = await fetch(`https://graph.microsoft.com${path}`, {
                method: method,
                body: payload ? JSON.stringify(payload) : undefined,
                headers: {
                    "Authorization": `Bearer ${this.graphToken.accessToken}`,
                    "Content-Type": "application/json",
                    ...additionalHeaders
                }
            });

            let returnData: string | object;

            const responseText = await response.text();

            try {
                returnData = JSON.parse(responseText);
            } catch {
                returnData = responseText;
            }
            return {
                statusCode: response.status,
                body: returnData
            }
        } catch (error) {
            return {
                statusCode: 400,
                body: `Graph request: Something went wrong.`
            }
        }
    }


    public invokeGraphBlobRequest = async (path: string) => {
        try {
            const response = await fetch(`https://graph.microsoft.com${path}`, {
                headers: {
                    Authorization: `Bearer ${this.graphToken.accessToken}`
                },
            });

            if (!response.ok) {
                throw new Error('Failed to get response.');
            }

            const blob = await response.blob();
            const objectUrl = URL.createObjectURL(blob as Blob);
            return objectUrl;

        } catch (error) {
            return null;
        }
    }

    public invokeGraphFileRequest = async (method: HTTPMethod, path: string, payload?: string | ArrayBuffer | null, additionalHeaders?: object): Promise<{ body: object | string, statusCode: number }> => {
        if (!this.graphToken) {
            await this.getGraphToken();
        }

        try {
            const response = await fetch(`https://graph.microsoft.com${path}`, {
                method: method,
                body: payload ? payload : undefined,
                headers: {
                    "Authorization": `Bearer ${this.graphToken.accessToken}`,
                    "Content-Type": "image/jpeg",
                    ...additionalHeaders
                }
            });

            let returnData: string | object;

            const responseText = await response.text();

            try {
                returnData = JSON.parse(responseText);
            } catch {
                returnData = responseText;
            }
            return {
                statusCode: response.status,
                body: returnData
            }
        } catch (error) {
            return {
                statusCode: 400,
                body: `Graph request: Something went wrong.`
            }
        }
    }
    //#endregion
}